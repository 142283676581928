@font-face {
  font-family: "Britannic Bold";
  src: url("font/8be4a2f403c2dc27187d892cca388e24.eot"); /* IE9*/
  src: url("font/8be4a2f403c2dc27187d892cca388e24.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("font/8be4a2f403c2dc27187d892cca388e24.woff2")
      format("woff2"),
    /* chrome、firefox */ url("font/8be4a2f403c2dc27187d892cca388e24.woff")
      format("woff"),
    /* chrome、firefox */ url("font/8be4a2f403c2dc27187d892cca388e24.ttf")
      format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url("font/8be4a2f403c2dc27187d892cca388e24.svg#Britannic Bold")
      format("svg"); /* iOS 4.1- */
}

:root {
  --clip-pos: 0 0;
}

*,
*::after,
*::before {
  -webkit-user-select: none;
  -webkit-user-select: text;
  -webkit-user-drag: none;
  -webkit-app-region: no-drag;
  user-select: none;
}

html {
  /*position: relative;*/
  min-height: 100%;
  font-size: 2vw;
}

body {
  background-color: black;
  user-select: none;
  /*font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  text-shadow: 0 0 0.5px rgba(58, 57, 57, 0.25);
  margin-top: 8rem;*/
}

html,
body,
#root {
  height: 100%;
}

.container-fluid {
  height: 100%;
  padding: 0px;
  /*padding-right: 5px;*/
}

figure {
  margin: 0px;
}
.figure-img {
  margin-bottom: 0px;
}

#hacker_div {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("pictures/backgrounds/hacker.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%; /* contain */
}

#hacker_synch {
  position: absolute; /* Sit on top of the page content */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: red;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
}

#button_reset {
  position: absolute;
  top: 0px;
  right: 50px;
  color: white;
  cursor: pointer;
}
#button_fs {
  position: absolute;
  top: 0px;
  right: 10px;
  color: white;
  cursor: pointer;
}
#button_sound {
  position: absolute;
  top: 20px;
  right: 30px;
  color: white;
}

#end_game_btn {
  position: absolute;
  bottom: 30px;
  right: 30px;
  z-index: 5;
}

#desktop_div {
  height: inherit;
}
.desktop_analyste {
  background-image: url("pictures/backgrounds/personnel2.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: local;
  background-color: white;
  height: inherit;
}
.desktop_directeur {
  background-image: url("pictures/backgrounds/officiel.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: local;
  background-color: #180090;
  height: inherit;
}

.tooltip {
  font-size: 50%;
}

.login-modal {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}
.login-modal-analyst {
  position: absolute;
  font-size: 50%;
  bottom: 0em;
  left: 5em;
}
.login-modal .modal-header,
.login-modal .modal-footer,
.login-modal-analyst .modal-header,
.login-modal-analyst .modal-footer {
  border-color: #a22;
}
.login-modal .modal-content,
.login-modal-analyst .modal-content {
  background-color: black;
  color: white;
  border-color: #a22;
  border-style: double;
  border-width: 5px;
}
.login-modal .modal-title,
.login-modal-analyst .modal-title {
  font-size: 1rem;
}
.login-modal .form-login .form-control {
  color: black;
  font-size: 0.9rem;
  width: 1.2rem;
  height: 1.8rem;
  padding: 0px 3px;
  border: 5px solid black;
}
.login-modal-analyst .form-mastermind {
  /*width: calc(100% - 0.1em);*/
  margin: 0.25rem;
  padding: 0em 0.5em;
  font-size: 1.4rem;
  color: black;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background-color: white;
  justify-content: center;
  display: flex;
}
.alert-director {
  position: absolute;
  top: 10px;
  left: 10px;
  max-width: 16rem;
  max-height: 36rem;
}
.alert-director img {
  transform: translate(-33%, 0);
  width: 1.25rem;
  height: 1.25rem;
}
.alert-director-message {
  position: absolute;
  margin-right: 2rem;
}
.alert-director-button-container {
  padding-top: 5rem;
  display: flex;
  justify-content: right;
  margin-top: 10px;
}
.alert-director-hints {
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 0.25rem;
  margin-bottom: 0.25rem;
}
.alert-mail {
  position: absolute;
  bottom: 0px;
  right: 20px;
  max-width: 35em;
  cursor: pointer;
}
.alert-mail img {
  width: 2em;
}
.alert-mail-mission {
  font-family: "Courier";
  font-size: 45%;
}
.alert-mail-mission .block2 {
  border: 3px blue solid;
  border-radius: 50px;
  padding: 20px 50px;
  background-color: #bbe;
  max-width: 60em;
}

.login-director-failed .content {
  padding: 1em;
  text-align: center;
  justify-content: center;
}

.desktop_folders_d {
  padding: 0px 10px;
  /*
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 5;
  */
}
.desktop_folders_a {
  padding: 0px 10px;
  /*
  position: relative;
  bottom: 30px;
  left: 30px;
  z-index: 5;
  */
}
.desktop_folders_a1 {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 5;
}

.desktop_icon {
  width: 5em;
  margin-right: 1em;
  margin-top: 0.5em;
  cursor: pointer;
}
.desktop_icon:hover {
  background-color: #80808030;
}
.desktop_icon_image {
  padding-left: 1em;
  padding-right: 1em;
}
.desktop_icon_caption {
  font-size: 56%;
  color: white;
}

.window_border {
  position: absolute;
  display: flex;
  top: 5em;
  left: 18em;
  padding: 3px;
  background-color: #bbb;
  border: 1px solid black;
  /*max-width: 60vw;
  max-height: 90vh;*/
  z-index: 10;
}

.window_border .window {
  background-color: whitesmoke;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  flex: auto;
}

.window .title {
  background: #bbb;
  color: black;
  text-align: center;
  padding: 2px;
  border-bottom: solid 1px black;
  font-size: 56%;
}

.window .title .close {
  display: inline-block;
  color: black;
  font-size: 100%;
  margin: 3px;
  cursor: pointer;
}

.window .content {
  position: relative;
  flex: auto;
  padding: 10px 20px;
  overflow: auto;
}

.window .content .background {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.window .content .inner_frame {
  width: 100%;
  height: 100%;
}

.window .content .dropdown-menu {
  font-size: 70%;
}

.window .forms {
  font-size: 70%;
}

.window .forms .form-control,
.window .forms .form-label,
.window .forms .btn {
  font-size: 70%;
  /*width: 100%;*/
}
.window .forms .btn {
  background-color: #333;
}

.cell-tracking-form .form-text {
  /* I dunno why but need that ... */
  z-index: 0;
}

.cellphone-loc-window .content {
  background-color: black;
}
.cellphone-localization {
  font-size: 0.6rem;
  padding: 10px;
}
.cellphone-localization img {
  max-width: 100%;
}

.citycam-form,
.citycam-form .form-group,
.citycam-form .form-label {
  font-size: 0.6rem;
  margin-bottom: 0rem;
  line-height: 0.9;
}
.citycam-form .form-group.group-sep {
  border-top: 3px black solid;
}
.citycam-form .form-group.group-sepdbl {
  border-top: 3px black solid;
  border-top-style: double;
}
.citycam-form .form-group.footer {
  border-top: 5px purple solid;
  margin-top: 30px;
}
.citycam-form h4 {
  margin-left: 3rem;
}
.citycam-form img {
  max-width: 100%;
  max-height: 100%;
}

.person-form,
.person-form .form-group,
.person-form .form-control,
.person-form .form-label,
.person-form .btn {
  font-size: 0.4rem;
  width: 100%;
  margin-bottom: 0.1rem;
}
.person-form .form-title img {
  width: 20em;
  max-width: 20%;
}
.person-form img {
  max-width: 100%;
  max-height: 100%;
}

.survey-form,
.survey-form .form-group,
.survey-form .form-control,
.survey-form .form-label {
  font-size: 0.4rem;
  width: 100%;
  margin-bottom: 0.1rem;
}
.survey-form .form-title {
  font-size: 0.7rem;
}
.survey-form .form-title-img img {
  width: 20em;
  max-width: 20%;
}
.survey-form img {
  max-width: 100%;
  max-height: 100%;
}
.survey-form .survey-part-title {
  margin-top: 10px;
  padding-left: 5px;
  background-color: lightgray;
  font-size: 0.6rem;
}
.survey-one-line {
  border-top: black 1pt solid;
  padding-bottom: 5px;
  padding-top: 5px;
}
.survey-form .flex-row-reverse label {
  margin-right: 1rem;
}
.survey-form-psychology {
  display: flex;
  flex-wrap: wrap;
}

input[type="checkbox"][readonly] {
  pointer-events: none;
}

@keyframes border-blinker {
  0% {
    border-color: #ff0000ff;
    opacity: 1;
  }
  100% {
    border-color: #ff000050;
    opacity: 1;
  }
}
@-webkit-keyframes border-blinker {
  0% {
    border-color: #ff0000ff;
    opacity: 1;
  }
  100% {
    border-color: #ff000050;
    opacity: 1;
  }
}
@-moz-keyframes border-blinker {
  0% {
    border-color: #ff0000ff;
    opacity: 1;
  }
  100% {
    border-color: #ff000050;
    opacity: 1;
  }
}
.border-blinking {
  animation: border-blinker 0.5s linear infinite alternate;
  -webkit-animation: border-blinker 0.5s linear infinite alternate;
  -moz-animation: border-blinker 0.5s linear infinite alternate;
}
.lockee-modal {
  position: absolute;
  font-size: 70%;
  top: 4em;
  left: 18em;
  width: 35em;
  max-width: 35em;
  z-index: 20;
}
.lockee-modal .modal-content {
  background-color: #00000000;
}
.lockee-modal .modal-body {
  background-image: url("pictures/backgrounds/fond de fenêtre alerte sécurité_800.jpg");
  background-size: 1000px;
  border: 10px solid red;
  border-radius: 0.5rem;
  padding-left: 8em;
}
.lockee-modal .modal-body .content {
  text-align: center;
  color: white;
  background-color: #0005;
  padding: 15px;
}
.lockee-modal .modal-body .content .timer {
  font-size: 150%;
  text-align: center;
  color: red;
}
.lockee-modal .modal-body .content .pense-bete {
  border: 2px white solid;
  padding: 10px;
  margin: 0px;
}
.lockee-modal .modal-body .content .pense-bete legend {
  font-size: 80%;
  margin: auto;
}
.lockee-modal .modal-body .content .img-fluid {
  max-width: 100%;
}
.lockee-modal .pattern-lock-wrapper {
  position: relative;
  margin-left: 9.4em;
}
.lockee-modal .lockee-numbers {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.lockee-modal .lockee-num {
  width: 33.3%;
  height: 33.3%;
  flex: 1 0 33.3%;
  z-index: 10;
}

.confirm-modal {
  position: absolute;
  font-size: 70%;
  top: 4em;
  left: 18em;
  min-width: 25vw;
  z-index: 50;
}
.confirm-modal .modal-header {
  justify-content: center;
}
.confirm-modal .modal-body {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.confirm-modal .modal-footer {
  justify-content: space-between;
}
.confirm-modal .btn {
  font-size: 1em;
}

.confirm-modal .restore-body {
  flex-direction: column;
}

.modal-2bis {
  position: absolute;
  font-size: 80%;
  left: 50%;
  transform: translate(-50%, 0);
  top: 4em;
  width: 30em;
  z-index: 50;
}
.modal-2bis .modal-header {
  justify-content: center;
  font-size: 50%;
}
.modal-2bis .modal-body {
  display: flex;
  justify-content: space-between;
}
.modal-2bis .modal-footer {
  justify-content: center;
}

.remote-control-popup {
  position: absolute;
  top: 0em;
  left: 15em;
  padding: 0 1em;
  z-index: 60;
  color: red;
  background-color: black;
}

.msdos .window {
  background-color: black;
  color: white;
}

.msdos .window h1 {
  line-height: 1.48em;
  font-size: 0.85em;
}

.advert .content {
  cursor: zoom-in;
  margin-top: 0em;
}
.advert .content img {
  cursor: zoom-in;
  z-index: 10;
}

.advert .hided-text {
  display: block;
  position: relative;
  line-height: 1.35em;
  font-size: 0.81em;
  color: white;
  z-index: 20;
  text-shadow: 1px 1px 2px #000, 0 0 1em #000, 0 0 0.2em #000;
  clip-path: circle(20% at var(--clip-pos));
}

.hided-text #tsunami,
.hided-text-no-clip #tsunami {
  color: red;
}

.hided-text-no-clip {
  position: absolute;
  /*line-height: 1.4em;*/
  font-size: 0.81rem;
  color: white;
  text-shadow: 1px 1px 2px #000, 0 0 1em #000, 0 0 0.2em #000;
  z-index: 5;
}

.blink_me {
  animation: blinker 1s linear infinite alternate;
}
.blink-me-fast {
  animation: blinker 0.2s linear infinite alternate;
}
@keyframes blinker {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

/* Audio mixer on DELTA desktop */
.audio-mix-delta {
  display: flex;
  justify-content: space-between;
  background-color: #000;
  min-height: 20vh;
  max-height: 40vh;
}
.audio-mix-delta-actions {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
}
.audio-mix-delta-assist {
  max-width: 2.55rem;
  margin: 1vh 0em;
  cursor: pointer;
}

.audio-mix-delta-play {
  max-width: 2.55rem;
  margin: 1vh 0em;
  cursor: pointer;
}
.audio-mix-delta-diag {
  position: relative;
  display: inline-block;
  width: 27em;
  cursor: pointer;
}
.audio-mix-delta-diag img {
  width: 100%;
  height: 100%;
}
.audio-mix-seek-bar {
  position: absolute;
  border-left: red 3px solid;
  top: 0.16rem;
  left: 0;
  height: 95.35%;
}

/* Audio mixer on ALPHA desktop */
.audio-mix-alpha div {
  display: flex;
}

/* Mini Game 1 : deminage on DELTA desktop */
#minigame-deminage {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 45em;
  max-width: 45em;
  padding-bottom: 10px;
  border: black 2px solid;
  background-color: rgb(150, 200, 235);
  z-index: 99;
}
#minigame-deminage .col {
  flex-grow: 0;
}
#minigame-deminage #wire-wrapper {
  border: black 1px solid;
  padding: 15px 10px;
}
#minigame-deminage img:hover {
  border: red 1px solid;
  opacity: 0.5;
  cursor: pointer;
}
#minigame-deminage .wire-choice {
  border: black 1px solid;
  opacity: 1;
  width: 2.5em;
}
#minigame-deminage .wire-border {
  display: flex;
  border: black 1px solid;
  opacity: 1;
}
#minigame-deminage #wire1,
#minigame-deminage #wire2,
#minigame-deminage #wire3 {
  border: black 1px solid;
  opacity: 1;
  width: 3em;
}
#minigame-deminage #wire1:hover,
#minigame-deminage #wire2:hover,
#minigame-deminage #wire3:hover {
  opacity: 0.5;
}
#minigame-deminage #wire1.unset,
#minigame-deminage #wire2.unset,
#minigame-deminage #wire3.unset {
  border: black 1px solid;
  opacity: 0;
  width: 3em;
}

#minigame-deminage button {
  margin: 0px;
}
#minigame-deminage button:hover {
  opacity: 0.5;
}

/* Mini Game 2 : crackage fullscreen on both desktop */
#minigame-crackage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: auto;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(40, 40, 40);
  z-index: 99;
}
#minigame-crackage img#base {
  object-fit: contain;
  max-width: 100%;
  max-height: 100vh;
}
#minigame-crackage img#cell,
#minigame-crackage img#text,
#minigame-crackage img#cell_game,
#minigame-crackage img#text_game,
#minigame-crackage img#light1,
#minigame-crackage img#light2,
#minigame-crackage img#light3,
#minigame-crackage img#light4,
#minigame-crackage img#light5,
#minigame-crackage img#light6,
#minigame-crackage img#light7 {
  position: absolute;
  height: auto;
}
#minigame-crackage map,
#minigame-crackage img#cell,
#minigame-crackage img#text,
#minigame-crackage img#light1,
#minigame-crackage img#light2,
#minigame-crackage img#light3,
#minigame-crackage img#light4,
#minigame-crackage img#light5,
#minigame-crackage img#light6,
#minigame-crackage img#light7 {
  cursor: pointer;
}
#minigame-crackage #timer {
  position: absolute;
  color: red;
  cursor: pointer;
}
#minigame-crackage #timer_game {
  position: absolute;
  color: red;
}

/* Mini Game 3 : piratage, fullscreen on both desktop */
#minigame-piratage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: auto;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  z-index: 99;
}
#minigame-piratage img#base {
  object-fit: contain;
  max-width: 100%;
  max-height: 100vh;
}
#minigame-piratage img#unlock {
  position: absolute;
  height: auto;
  right: 10px;
  bottom: 0px;
}
#minigame-piratage img#text {
  position: absolute;
  height: auto;
  top: 10px;
}
#minigame-piratage div#mask0,
#minigame-piratage div#mask1,
#minigame-piratage div#mask2,
#minigame-piratage div#mask3 {
  position: absolute;
  top: 10px;
  background-color: #000b;
}
#minigame-piratage div#word_mask {
  position: absolute;
  top: 10px;
  background-color: rgba(50, 250, 50, 0.5);
  border: 0px red solid;
}
#minigame-piratage #matrix_hide,
#minigame-piratage #matrix {
  position: absolute;
  line-height: 0px;
  top: 0px;
  left: 0px;
}
#minigame-piratage #matrix_hide {
  background-color: #000b;
}
#minigame-piratage #matrix .cell {
  display: inline-flex;
  width: 100px;
  height: 70px;
  margin: 5px;
}
#minigame-piratage #matrix .cell[status="1"] {
  background-color: rgba(100, 200, 255, 0.5);
}
#minigame-piratage #matrix .cell[status="2"] {
  background-color: rgba(100, 200, 255, 0.5);
  border: red 3px solid;
}
#minigame-piratage #matrix .cell[status="3"] {
  background-color: rgba(150, 0, 255, 0.5);
}
#minigame-piratage #matrix .cell:hover {
  background-color: rgba(50, 250, 50, 0.5);
  cursor: pointer;
}

.final-report {
  font-family: "Britannic Bold" !important;
  font-size: 1.4vw;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("pictures/backgrounds/filtre brun.png");
  background-size: cover;
  background-repeat: repeat-y;
  max-width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px 40px;
}
.final-report img {
  max-width: 100%;
}
.final-report .report-two-cols {
  column-gap: 40px;
  column-count: 2;
  text-align: justify;
  text-justify: inter-word;
}
.final-report .report-one-col {
  column-gap: 40px;
  column-count: 1;
  text-align: justify;
  text-justify: inter-word;
}

/* TSUNAMI ChatBot */
.chatbot-container {
  font-size: 50%;
  margin: 0px 0;
  /*display: flex;*/
  justify-content: center;
  position: fixed;
  right: 3em;
  bottom: 8em;
  z-index: 9999;
  box-shadow: 5px 5px 13px rgba(91, 81, 81, 0.4);
  border-radius: 5px;
}
.chatbot-container button {
  font-size: unset;
}
.react-chatbot-kit-chat-header {
  display: flex;
  justify-content: space-between;
}
.react-chatbot-kit-chat-header .chatbot-header-close,
.react-chatbot-kit-chat-header .chatbot-header-close:focus {
  border: none;
}
.react-chatbot-kit-chat-header .chatbot-header-close:hover {
  color: red;
}

.react-chatbot-kit-chat-container {
  width: 40vw !important;
}
.react-chatbot-kit-chat-inner-container {
  height: 75vh !important;
}
.react-chatbot-kit-chat-message-container {
  height: 66vh !important;
}
.react-chatbot-kit-chat-btn-send {
  background-color: #eee !important;
}
.react-chatbot-kit-chat-btn-send:hover {
  background-color: #cfc !important;
}
.react-chatbot-kit-chat-btn-send svg {
  width: 30% !important;
  fill: rgb(64, 207, 64) !important;
}
.react-chatbot-kit-chat-header,
.react-chatbot-kit-chat-bot-message,
.react-chatbot-kit-user-chat-message,
.react-chatbot-kit-chat-input {
  font-size: 1em !important;
}
.react-chatbot-kit-chat-input-container {
  display: none !important;
}
.react-chatbot-kit-chat-bot-message {
  width: 100% !important;
  color: black !important;
  background-color: #cfc !important;
}
.react-chatbot-kit-chat-bot-message-arrow {
  border-right-color: #cfc !important;
}
#chatbot-loader {
  fill: black !important;
}
.chatbot-button-widget {
  position: absolute;
  bottom: -7em;
  left: 0em;
  width: 90%;
  display: flex;
  flex-direction: column;
  box-shadow: 5px 5px 13px rgba(91, 81, 81, 0.4);
}
.chatbot-button-widget-label {
  background-color: #fff8;
}
.chatbot-button-widget .chatbot-button-widget-intern {
  display: flex;
  flex-grow: 1;
  align-items: center;
}
.chatbot-button-widget-intern img {
  justify-content: flex-start;
  width: 4em;
}
.chatbot-button-widget-intern span {
  flex-grow: 1;
  justify-content: flex-end;
}

.chatbot-button {
  position: fixed;
  bottom: 1em;
  right: 1em;
  width: 2em;
  margin: 0;
  z-index: 9999;
}
.chatbot-button:hover {
  background-color: #00000000;
}
.chatbot-button-image {
  font-size: 50%;
  border-radius: 20px;
  background-color: white;
  border: none;
  padding: 0;
}
.chatbot-button-image:hover {
  background-color: #cfc;
}

.chatbot-message[unread] {
  background-color: pink;
  animation: blinker 0.2s linear infinite alternate;
}

.fb-card {
  display: inline-flex;
  position: sticky;
  margin-left: auto;
  margin-right: auto;
  top: 800px;
  left: 50px;
  width: auto;
  box-shadow: 10px 10px 5px grey;
}
.fb-card:hover {
  background-color: grey;
  border: 5px solid #4267b2;
}
.fb-card svg {
  color: #4267b2;
}

/* ----------------------------------------------
* Generated by Animista on 2021-2-27 0:54:30
* Licensed under FreeBSD License.
* See http://animista.net/license for more info.
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */
.scale-out-center {
  animation: scale-out-center 0.8s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
/**
 * ----------------------------------------
 * animation scale-out-center
 * ----------------------------------------
 */
@keyframes scale-out-center {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 1;
  }
}

.clickEffect {
  position: fixed;
  box-sizing: border-box;
  border-style: solid;
  border-color: #ffffff;
  border-radius: 50%;
  animation: clickEffect 0.4s ease-out;
  z-index: 99999;
}
@keyframes clickEffect {
  0% {
    opacity: 1;
    width: 0.5em;
    height: 0.5em;
    margin: -0.25em;
    border-width: 0.5em;
  }
  100% {
    opacity: 0.2;
    width: 4em;
    height: 4em;
    margin: -2em;
    border-width: 0.03em;
  }
}
.modal-score {
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  background-color: #000000;

  padding-bottom: 1rem;
  z-index: 10;
  overflow: auto;
}
.modal-score-url {
  position: relative;
  left: 50vw;
  transform: translate(-50%, 0);
  max-width: 100vh;
}
.modal-score-game {
  position: absolute;
  width: 32vw;
  top: 1rem;
  left: 1rem;
  box-shadow: 0 7px 14px rgba(255, 255, 255, 0.12),
    0 5px 5px rgba(255, 255, 255, 0.12);
}
.modal-score-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Britannic Bold";
}
.modal-score-row-left {
  width: 60%;
  color: #fff;
  padding-right: 0rem;
  line-height: 0.8;
}
.modal-score-row-right {
  width: 40%;
  display: flex;
  flex-direction: row;
  text-align: left;
}
.modal-score-row-right span {
  color: #fff14d;
  font-weight: bold;
  font-smooth: 2rem;
  padding-right: 1rem;
  text-align: left;
  padding-left: 0rem;
}
.modal-score-fallback {
  display: grid;
  place-items: center;
  position: absolute;
  top: 1rem;
  left: 50%;
  padding: 0.1rem 0.75rem 0.2rem 0.75rem;
  transform: translate(-50%, 0);
  border-radius: 1rem;
  background-color: #000000;
  box-shadow: 0 7px 14px rgba(255, 255, 255, 0.12),
    0 5px 5px rgba(255, 255, 255, 0.12);
}
.background-score {
  position: relative;
  left: 50vw;
  transform: translate(-50%, 0);
  max-width: 100vw;
  max-height: 100vh;
  object-fit: fill;
}
.score-link {
  justify-content: center;
  text-align: center;
  color: white;
}
.score-link .timer {
  color: red;
  cursor: pointer;
}
.icon-score {
  width: 1.3rem;
  height: 1.3rem;
  margin-right: 0.2rem;
}
.tinted {
  filter: grayscale(50%) brightness(30%);
}
.sticky-btn-container {
  position: fixed;
  display: flex;
  justify-content: space-between;
  bottom: 40px;
  z-index: 20;
}
.sticky-btn {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
  border-radius: 40px;
  cursor: pointer;
  box-shadow: 0 7px 14px rgba(0, 0, 0, 0.12), 0 5px 5px rgba(0, 0, 0, 0.12);
}
.sticky-btn:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.fb-sticky {
  background-color: #627fff;
}
.fb-sticky:hover {
  background-color: #90a4ff;
}
.sticky-text {
  font-weight: bold;
  color: #fff;
  text-shadow: 1px 1px 1px #3b5998, 0 0 1em #3b5998, 0 0 0.2em #3b5998;
  z-index: 30;
}
.recommendation {
  background-color: #9c61ff;
}
.recommendation:hover {
  background-color: #b78cff;
}
